import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: oarmstrong@protonmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/cukimonkey",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/orsolya-armstrong-9b949437/",
  },

];

const Header = () => {
  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      backgroundColor="#18181b"
    >
      <Box color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={16}
          py={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <nav>
            <HStack spacing={20}>
              {socials.map((socials) => (
                <a href={socials.url}>
                  <FontAwesomeIcon icon={socials.icon} size='2x' color="#FF61C0" />
                </a>
              ))}
            </HStack>
          </nav>
          <nav>
            <HStack spacing={10} color="#FF61C0">
              <a href="#projects" onClick={handleClick("projects")} > Projects</a>
             
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;