
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";


function Card ({ title, description, imageSrc, link })  {

    const handleCardClick = () => {
        window.open(link, '_blank');
      };

return(
  <div className="CardContainer" style={{color:"black", backgroundColor: 'white', borderRadius: "1em" }}>
    <div className="CardImage">
      <img src={imageSrc} style={{borderRadius: "1em"}}/>
    </div>
    <div className="CardHeader" style={{fontSize: "30px", margin: '20px'}}>{title}</div>
    <div className="CardDescription" style={{fontSize: "17px", margin: '20px', textAlign: "justify"}}>{description}</div>
    <div className="CardArrow" style={{fontSize: "17px", color: "blue", margin: '20px'}} onClick={handleCardClick}
    > Go to project... <FontAwesomeIcon icon={faArrowRight} size="1x" /></div>

  </div>
);

}
export default Card;