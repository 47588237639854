import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "Pocket Hungarian",
    description:
      "I developed Pocket Hungarian, an Android app built with Android Studio and Java. It offers a free and intuitive way to learn Hungarian. Practice pronunciation with authentic audio and enhance memorization through playful illustrations. ",
      imageSrc: process.env.PUBLIC_URL + "/pocket_hungarian.jpg",
      link: "https://play.google.com/store/apps/details?id=com.my.first.app.pockethungarian&hl=en&gl=US&pli=1"
  },
  
  {
    title: "Quiz Game",
    description:
      "Experience the excitement of my Quiz Game, a dynamic web application built with React, React DOM, and HTML5. With the added feature of React Modals, the game offers a seamless experience.",
      imageSrc: process.env.PUBLIC_URL + "/quiz_game.jpg",
      link: "https://quizapp-lyart.vercel.app/"
  },

];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="#18181b"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
      
    >
      <Heading as="h1" id="projects-section">
        Featured Projects
      </Heading>
      <Box className="BoxContainer"
        
      >
        {projects.map((project) => (
          <Card
            
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.imageSrc}
            link={project.link}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;