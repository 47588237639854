import React from "react";
import { Avatar } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import { IconButton } from '@chakra-ui/react';
import { DownloadIcon } from "@chakra-ui/icons";



const greeting = "Hello, I am Ursula!";
const bio1 = "A junior frontend developer";
const bio2 = "specialised in React";

function downloadFile() {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/CV.pdf';
    link.download = 'CV.pdf';
    link.click();
  }
const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#2A4365"
    fontSize="50px"
    
  >
  <Avatar alt="Ursula Armstrong" src={process.env.PUBLIC_URL + "/avatar1.jpg"} sx={{ width: 56, height: 56 }}/>
  
  <h1 style={{fontSize: "40px"}}>{greeting}</h1> <br></br>
  <h2>{bio1}</h2>
  <h2>{bio2}</h2>
  <br></br>
  <IconButton

    id="downloadButton"
    onClick={downloadFile}
    colorScheme='teal'
    size='lg'
    aria-label='Download Resume'
    icon={<DownloadIcon />}
    />
    <h2 style={{ fontSize: '15px' }}>Download Resume</h2>
  </FullScreenSection>
  
);

export default LandingSection;